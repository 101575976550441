import React from "react";

const CategorySchema = ({ category, products }) => {
    const schemaData = {
        "@context": "https://schema.org",
        "@type": "CollectionPage",
        "name": category && category.name ? category.name : '',
        // "itemListElement": category && category.childs && category.childs.map((category, index) => ({
        //     "@type": "ListItem",
        //     "position": index + 1,
        //     "name": category.name,
        //     "item": category.alias
        // })),
        "mainEntity": products.map((product) => ({
            "@type": "Product",
            "name": product.name,
            "image": (product && product.image) ? product.image : "../../../image/logo.png",
            "description": product.description,
            "sku": product.model,
            "brand": {
                "@type": "Brand",
                "name": product.manufacturer_name
            },
            "offers": {
                "@type": "Offer",
                "priceCurrency": "UAH",
                "price": product.price,
                "availability": "https://schema.org/InStock",
                "seller": {
                    "@type": "MedicalOrganization",
                    "name": "MedBuy Online"
                }
            }
        }))
    };

    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
    );
};

export default CategorySchema;
