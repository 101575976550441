import React from "react";

const ProductSchema = ({ product }) => {
    const schemaData = {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": product.name,
        "image": (product && product.images) ? product.images[0] : "../../../image/logo.png",
        "description": product.description,
        "sku": product.model,
        "brand": {
            "@type": "Brand",
            "name": product.manufacturer_name
        },
        "offers": {
            "@type": "Offer",
            "priceCurrency": "UAH",
            "price": product.price,
            "itemCondition": "https://schema.org/NewCondition",
            "availability": "https://schema.org/InStock",
            "seller": {
                "@type": "MedicalOrganization",
                "name": "MedBuy Online"
            }
        }
    };

    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
    );
};

export default ProductSchema;
