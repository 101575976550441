import React from 'react';
import './ProductsGallery.css'
import {Link} from "react-router-dom";
import {formatSum, isMobile} from "../../../store/reducers/MainReducer";
import LangLink from "../../Common/LangLink";

const ProductsGallery = (props) => {

    const renderGrid = (products, count, addToCart) => {
        return products && products.length > 0 ? products.map((e,i) => {
            return i + 1 <= count ? <ProductGalleryCard product={e} addToCart={addToCart} type={props.type} checkCartAdded={props.checkCartAdded} getTranslate={props.getTranslate}/> : '';
        }) : []
    }

    const renderSlider = (products) => {
        return <span>Slider</span>
    }

    if((!props.products || props.products.length === 0) && (props.type !== 'equivalent') && (props.type !== 'searched')) {
        return <h4 className={'text-center'}>{props.getTranslate('front_common','not_found')}</h4>;
    }
    return (
        <div className={'products_gallery_main_container'}>
            {props.type !== 'category' && props.products && props.products.length > 0 &&
                <div className="products_gallery_main_title">
                    <h3>{props.title}</h3>
                </div>
            }
            <div className="products_gallery_items row">
                {props.view === 'grid' ? renderGrid(props.products, props.count, props.addToCart) : renderSlider(props.products)}
            </div>
        </div>
    )
}

export default ProductsGallery;

const ProductGalleryCard = (props) => {
    const addToCart = () => {
        props.addToCart(props.product);
        window.dataLayer = window.dataLayer || [];
        try {
            window.dataLayer.push({
                event: 'add_to_cart',
                ecommerce: {
                    items:[
                        {
                            item_name: props.product.name, // Назва товару
                            item_id:props.product.id, // Унікальний ідентифікатор
                            price: props.product.price, // Ціна
                            currency: "UAH", // Вказати валюту
                            item_category: props.product.manufacturer_name, // Категорія
                            quantity: 1, // Кількість
                        },
                    ]
                }
            });
        } catch(error) {
            console.log(error);
        }
    };

    return(
        <div className={'product_gallery_item ' + (isMobile() ? 'col-md-6' : props.type === 'category' ? 'col-md-4' : 'col-md-3')}>
           <div className={'product_gallery_container'}>
                    {props.product && props.product.new_product && Number(props.product.new_product) === 1 ?
                        <div className={"ribbon-wrapper ribbon-lg"}>
                            <div className="ribbon bg-warning">
                                {props.getTranslate('front_product_cart','new_product_flag')}
                            </div>
                        </div>
                    : ""}
                <div className={'product_gallery_image'}>
                    <LangLink to={'/products/'+ props.product.alias } title={props.product.name}><img src={props.product.image} /></LangLink>
                </div>
                <div className={'product_gallery_name'}>
                    <LangLink to={'/products/'+ props.product.alias } title={props.product.name}>{props.product.name && (props.product.name.length > 41 ? props.product.name.substr(0,70) + '...' : props.product.name)}</LangLink>
                </div>
                <div className={'product_gallery_options'}>
                    {/*<p>{props.getTranslate('front_product_cart','professional_test_for_medical_institutions')}</p>*/}
                    <p><span>{props.getTranslate('front_product_cart','product_cart_manufacturer')}:</span> <span>{props.product.manufacturer_name}</span></p>
                    <p><span>{props.getTranslate('front_product_cart','product_cart_model')}:</span> <span>{props.product.model}</span></p>
                    {props.product.price_one ? <p className={"product_gallery_discount_price_one_container"}><span>{props.getTranslate('front_product_cart','product_cart_price_one')}: </span>
                        {props.product.old_price && props.product.old_price > 0 ?
                            <div className={"product_gallery_discount_price_one_in_container"}>
                                <div className={"product_gallery_price_discount_old_price"}>₴ {Number(formatSum(props.product.old_price_one)).toFixed(2)}</div>
                                <div className={"product_gallery_price_discount"}>₴ {Number(formatSum(props.product.price_one)).toFixed(2)}</div>

                            </div> : <span className={"product_gallery_price_one_static"}> ₴ {Number(formatSum(props.product.price_one)).toFixed(2)}</span>
                        }
                    </p> : ''}
                    <p><span>{props.getTranslate('front_product_cart','product_cart_in_pack')}:</span> <span>{props.product.in_pack} {props.getTranslate('front_product_cart','product_cart_unit')}.</span></p>
                    {/*{props.product.options && props.product.options*/}
                    {/*    .filter(e => Number(e.id) === 6)*/}
                    {/*    .map(e => {*/}
                    {/*    return <p><span>{e.name}:</span> <span>{e.value}</span></p>*/}
                    {/*})}*/}
                </div>
               {props.product.old_price && props.product.old_price > 0 ?
                   <div className={"product_gallery_discount_price_container"}>
                       <div className={"product_gallery_price_discount_old_price"}>₴ {formatSum(props.product.old_price)}</div>
                       <div className={"product_gallery_price_discount"}>₴ {formatSum(props.product.price)}</div>

                   </div>
                   : <div className={'product_gallery_price'}>₴ {formatSum(props.product.price)}</div>
               }
                <div className={'product_gallery_button'}>
                    {props.checkCartAdded(props.product.count > 0 ? 'sale' : 'preorder', props.product.id) ?
                        <span className={'product_button_in_cart'}> <i className="fas fa-check-circle"></i> {props.getTranslate('front_product_cart','product_cart_in_card')}</span>
                        :
                        props.product.count > 0 ?
                            <button onClick={addToCart}><i className="fas fa-cart-plus"></i> {props.getTranslate('front_product_cart','product_cart_add_in_card')}</button>
                            :
                            <button onClick={addToCart}><i className="fas fa-truck"></i> {props.getTranslate('front_product_cart','product_cart_add_in_card_preorder')}</button>

                    }

                </div>
               {props.product.discount_percent && props.product.discount_percent > 0 ?
                   <div className={"product_gallery_discount_percent_container"}>
                       <span>-{Math.floor((1 - Number(props.product.discount_percent).toFixed(2))*100) ? Math.floor((1 - Number(props.product.discount_percent).toFixed(2))*100) : 1}%</span>
                   </div>
                   : ""
               }
            </div>
        </div>
    );
}
