import {Link} from "react-router-dom";
import './About.css';
import MetaTags from "react-meta-tags";
import React from "react";
import {SeoTagsStatic} from "../../SeoTagsStatic";
import ShemaAbout from "../../Common/Shema/ShemaAbout";

const TextLanguageUkr = <>
    <ShemaAbout/>
    <SeoTagsStatic page={'about'}/>
    <MetaTags>
        {/*<title>{"Про нас | MedBuy"}</title>*/}
        <meta name="description" content={"ПП Медбай - MedBuy.online є дистриб’ютором експрес-тестів відомих європейських брендів Ameda (Австрія), Screen Italia та InterMedical (Італія), VitAssay (Іспанія), сертифіковані в Україні, для лікарів первинної ланки (сімейні лікарі, терапевт, педіатр)"} />
        <meta name="keywords" content={"експрес тест, covid-19, антиген, діагностика, захворювань, Європа, бал, сертифікат, інструкція,  АМЕДА, SCREEN Italia, InterMedical, Дистрибютор в  Україні, доставка по Україні, "} />
    </MetaTags>
    <div className={"products_gallery_main_title"}>
        <h3>Про нас</h3>
    </div>
    <p className={"info_page_text_center"}>Компанія MedBuy є дистриб’ютором діагностичних експрес-тестів,
        призначених для професійного використання кваліфікованим медичним персоналом.</p>

    <h4 className={"info_page_title"}>Місія</h4>

    <span>Зробити якісну лабораторну діагностику простою, швидкою, недорогою і доступною в будь-якому медичному закладі – у кабінеті лікаря або біля ліжка пацієнта.</span>

    <h4 className={"info_page_title"}>Клієнти</h4>

    <span>Наші клієнти – лікарі первинної ланки надання медичної допомоги, сімейні лікарі, лікарі приватної практики, приватні клініки різного профілю, медичні діагностичні лабораторії, медичні підрозділи навчальних закладів і компаній приватного сектору. Ми розвиваємо <a
        className={"info_page_link"} href="/partner_program" target="_blank">партнерську програму</a> для лікарів з метою популяризації експрес-тестування.</span>

    <h4 className={"info_page_title"}>Спеціалізація</h4>

    <span>Ми пропонуємо якісні професійні експрес-тести Tubuva (Чехія), а також тести відомих європейських брендів Ameda (Австрія), Screen Italia та InterMedical (Італія), VitAssay (Іспанія).
        <br/>Постачаємо реагенти для імуноферментних аналізів ІФА TestLine (Чехія), лабораторне обладнання і реагенти від BioMaxima (Польща), зокрема автоматичні аналізатори біохімії крові, гематології та сечі.
        <br/>Тести є засобами діагностики in vitro (IVD) для використання в умовах лікувально-профілактичних закладів. Ми <a
        href="/payment_information"
        className={"info_page_link"}
        target="_blank">доставляємо продукцію по всій Україні</a>.</span>

    <h4 className={"info_page_title"}>Юридична інформація</h4>

    <p>
        Приватне підприємство <b>«Медбай»</b><br/>
        www.medbuy.online<br/>
        <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>Код ЄДРПОУ:</div> <div>32658838</div></div>
        <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>Телефон:</div> <div>+380 98 353 5560</div></div>
        <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>Ел. пошта:</div> <div>info@medbuy.online</div></div>
        <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>Адреса:</div> <div>Україна 04205, м. Київ, вул. Лук`яненка Левка, буд. 29, офіс 113</div></div>
        <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>Банк:</div> <div>ОТП Банк</div></div>
        <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>IBAN:</div> <div>UA963005280000026004000001944</div></div>
        <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>МФО:</div> <div>300528</div></div>
    </p>
</>

const TextLanguageEng = <>
    <SeoTagsStatic page={'about'}/>
    <MetaTags>
        {/*<title>{"Про нас | MedBuy"}</title>*/}
        <meta name="description" content={"ПП Медбай - MedBuy.online є дистриб’ютором експрес-тестів відомих європейських брендів Ameda (Австрія), Screen Italia та InterMedical (Італія), VitAssay (Іспанія), сертифіковані в Україні, для лікарів первинної ланки (сімейні лікарі, терапевт, педіатр)"} />
        <meta name="keywords" content={"експрес тест, covid-19, антиген, діагностика, захворювань, Європа, бал, сертифікат, інструкція,  АМЕДА, SCREEN Italia, InterMedical, Дистрибютор в  Україні, доставка по Україні, "} />
    </MetaTags>
    <div className={"products_gallery_main_title"}>
        <h3>About Us</h3>
    </div>
    <p className={"info_page_text_center"}>MedBuy is a distributor of rapid diagnostic tests for professional
        (point-of-care) use.</p>

    <h4 className={"info_page_title"}>Mission</h4>

    <span>To make good quality diagnostics easy, quick, affordable and accessible in any healthcare facility – in a doctor’s office or at patient’s bedside.</span>

    <h4 className={"info_page_title"}>Customers</h4>

    <span>Our customers are primary care physicians, general practitioners, family doctors, private medical practices and clinics, medical diagnostics labs, health units of schools and businesses. We are promoting our <a
        className={"info_page_link"} href="/partner_program" target="_blank">Partner Programme</a> for physicians to popularize rapid testing.</span>

    <h4 className={"info_page_title"}>Focus</h4>

    <span>We offer a wide selection of high-quality professional Tubuva rapid tests (Czechia) and also tests of well-known European brands Ameda (Austria), Screen Italia and InterMedical Diagnostics (Italy) and VitAssay (Spain). <br/>Our rapid tests are in vitro diagnostics (IVD) devices for professional point-of-care use.
<br/>Our product range for laboratories include TestLine reagents (Czech Republic) for enzyme-linked immunosorbent assays (ELISA), laboratory equipment and reagents from BioMaxima (Poland), including automatic blood biochemistry, hematology and urine analyzers. We <a
        href="/payment_information"
        className={"info_page_link"}
        target="_blank">ship to all destinations in Ukraine</a>.</span>

    <h4 className={"info_page_title"}>Legal Information</h4>

    <p>
        <b>MedBuy</b> Private Enterprise<br/>
        www.medbuy.online<br/>
    <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>Registration Code:</div> <div>32658838</div></div>
    <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>Telephone:</div> <div>+380 98 353 5560</div></div>
    <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>E-mail:</div> <div>info@medbuy.online</div></div>
    <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>Address:</div> <div>Office 113, 29 Marshala Tymoshenka St., Kyiv 04205, Ukraine</div></div>
    <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>Bank:</div> <div>OTP Bank</div></div>
    <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>IBAN:</div> <div>UA963005280000026004000001944</div></div>
    <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>MFO Bank Code:</div> <div>300528</div></div></p>
</>

const TextLanguageRus = <>
    <SeoTagsStatic page={'about'}/>
    <MetaTags>
        {/*<title>{"Про нас | MedBuy"}</title>*/}
        <meta name="description" content={"ПП Медбай - MedBuy.online є дистриб’ютором експрес-тестів відомих європейських брендів Ameda (Австрія), Screen Italia та InterMedical (Італія), VitAssay (Іспанія), сертифіковані в Україні, для лікарів первинної ланки (сімейні лікарі, терапевт, педіатр)"} />
        <meta name="keywords" content={"експрес тест, covid-19, антиген, діагностика, захворювань, Європа, бал, сертифікат, інструкція,  АМЕДА, SCREEN Italia, InterMedical, Дистрибютор в  Україні, доставка по Україні, "} />
    </MetaTags>
    <div className={"products_gallery_main_title"}>
        <h3>О нас</h3>
    </div>
    <p className={"info_page_text_center"}>Компания MedBuy является дистрибьютором диагностических экспресс-тестов для профессионального использования квалифицированным медицинским персоналом.</p>

    <h4 className={"info_page_title"}>Миссия</h4>

    <span>Сделать качественную лабораторную диагностику простой, быстрой, недорогой и доступной в любом медицинском учреждении – в кабинете врача или у постели пациента.</span>

    <h4 className={"info_page_title"}>Клиенты</h4>

    <span>Наши клиенты – врачи первичного звена оказания медицинской помощи, семейные врачи, врачи частной практики, частные клиники различного профиля, медицинские диагностические лаборатории, медицинские подразделения учебных заведений и предприятий. Мы развиваем <a
        className={"info_page_link"} href="/partner_program" target="_blank">партнёрскую программу</a> для врачей с целью популяризации экспресс-тестирования.</span>

    <h4 className={"info_page_title"}>Специализация</h4>

    <span>Мы предлагаем качественные профессиональные экспресс-тесты различного типа известных европейских брендов Ameda (Австрия), Screen Italia и InterMedical (Италия), VitAssay (Испания), Tody Laboratories (Румыния). Тесты являются средствами диагностики in vitro (IVD) для использования в условиях лечебно-профилактических учреждений. Все тесты имеют сертификацию CE. Мы <a
        href="/payment_information"
        className={"info_page_link"}
        target="_blank">доставляем продукцию по всей Украине</a>.</span>

    <h4 className={"info_page_title"}>Юридическая информация</h4>

    <p>
        Частное предприятие <b>«Медбай»</b><br/>
        www.medbuy.online<br/>
        <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>Код ЕГРПОУ:</div> <div>32658838</div></div>
        <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>Телефон:</div> <div>+380 98 353 5560</div></div>
        <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>Эл. почта:</div> <div>info@medbuy.online</div></div>
        <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>Адрес:</div> <div>Украина 04205, г. Киев, ул. Маршала Тимошенко, д. 29, офис 113</div></div>
        <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>Банк:</div> <div>ОТП Банк</div></div>
        <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>IBAN:</div> <div>UA963005280000026004000001944</div></div>
        <div className={"info_page_about_text_info_container"}><div className={"info_page_about_text_info_company"}>МФО:</div> <div>300528</div></div>
    </p>
</>

const TextLanguage = localStorage.getItem('medbuy_store_main_config_lang') &&
        localStorage.getItem('medbuy_store_main_config_lang') == 1 ? TextLanguageUkr
        : localStorage.getItem('medbuy_store_main_config_lang') == 2 ? TextLanguageEng
        : localStorage.getItem('medbuy_store_main_config_lang') == 3 ? TextLanguageRus : " ";


const About = (props) => {
    return (
        TextLanguage
    )
}

export default About;