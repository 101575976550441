import React from "react";
const BreadcrumbSchema = ({ breadcrumbs }) => {

    const schemaData = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": breadcrumbs && breadcrumbs.map((breadcrumb, index) => ({
            "@type": "ListItem",
            "position": index++,
            "name": breadcrumb?.name,
            "item": breadcrumb?.link
        }))
    };

    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
    );
};

export default BreadcrumbSchema;
