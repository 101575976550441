import React from "react";

const ContactSchema = () => {
    const schemaData = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "MedBuy Online",
        "image": "../../../image/logo.png",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "вул. Лук`яненка Левка",
            "addressLocality": "Київ",
            "postalCode": "04205",
            "addressCountry": "UA"
        },
        "telephone": "+380983535560",
        "email": "info@medbuy.online",
        "url": "https://medbuy.online",
        "openingHours": "Mo-Fr 09:00-18:00"
    };

    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
    );
};

export default ContactSchema;
