import React from "react";

const SchemaMarkup = () => {

    const schemaData = {
        "@context": "https://schema.org",
        "@type": "MedicalOrganization",
        "name": "MedBuy Online",
        "url": "https://medbuy.online",
        "logo": "../../../image/logo.png",
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+380983535560",
            "contactType": "customer service"
        }
    };

    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
    );
};

export default SchemaMarkup;
