import React from 'react';
import ProductsGalleryContainer from "../../Products/Gallery/ProductsGalleryContainer";
import Breadcrumbs from "../../Common/Breadcrumps/Breadcrumps";
import FiltersContainer from "../Filters/FiltersContainer";
import PaginationContainer from "../../Common/Pagination/PaginationContainer";
import MetaTags from "react-meta-tags";
import ShemaCategory from "../../Common/Shema/ShemaCategory";

const ProductsCategory = (props) => {
    return (
        <div className={'products_category_main_container'}>
            <ShemaCategory products={props.products} category={props.category} />
            <MetaTags>
                <title>{props.category && props.category.meta_title}</title>
                <meta name="description" content={props.category && props.category.meta_description} />
                <meta name="keywords" content={props.category && props.category.meta_keywords} />
            </MetaTags>
            <Breadcrumbs name={props.category && props.category.name}
                         pages={props.category && props.category.parent_data ? [
                             {name: props.category.parent_data.name, link: '/category/' + props.category.parent_data.alias},
                         ] : []}
            />
            <div className="row">
                <div className="col-md-3">
                    <FiltersContainer categoryAlias={props.activeCategory} type={'category'}/>
                </div>
                <div className="col-md-9">
                    <div className="row">
                        <div className="products_category_main_title">
                            <h1>{props.category && props.category.name}</h1>
                            {props.products && <span className={'products_category_main_title_count'}>{props.getTranslate('front_product_category','product_category_amount')}: {props.countAll}</span>}
                        </div>
                    </div>
                    <ProductsGalleryContainer title={props.getTranslate('front_product_category','product_category_title_recomend_product')} count={9} type={'category'} view={'grid'} products={props.products}/>
                    <PaginationContainer link={`/category/${props.activeCategory}?page=`} page={props.page}/>
                </div>
            </div>
        </div>
    )
}

export default ProductsCategory;